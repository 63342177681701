<template>
  <div class="animated fadeIn">
    <h1>Добро пожаловать</h1>
  </div>
</template>

<script>
import {enterToDashboardPage} from '../services/api';

export default {
  name: 'Dashboard',
  created() {
    if (this.$store.getters["user"].ownerType === 'contractor') {
      this.collectData();
    }   
  },
  methods: {
    async collectData() {
      let dateNow = new Date,
        data = {
          date: dateNow.toLocaleDateString("ru-Ru", {timeZone: "Europe/Moscow"}),
					time: dateNow.toLocaleTimeString("ru-Ru", {timeZone: "Europe/Moscow"}),
          clickType: 'Зашел на сайт',
          sessionId: this.$cookies.get('Session'),
        };

        if(this.$store.getters["user"].INN) {
          data["inn"] = this.$store.getters["user"].INN;
        }
        
        if (this.$store.getters["user"].company) {
          data["organization"] = this.$store.getters["user"].company.replace(
						/[^А-яЁё A-Za-z]/g,
						""
					);
        } else {
          data["organization"] = null;
        }

      await enterToDashboardPage(data);
    }
  },
};
</script>
